:root {
  --colorNeutralBackground1: #ffffff;
  --colorNeutralForeground1: #3e4450;
  --colorNeutralForeground2: #707882;
  --colorPaletteBlueBackground1: #cdd3ec;
  --colorPaletteVioletBackground1: #1e004d;
  --colorPaletteVioletBackground2: #7562fc;
  --colorPaletteVioletBackground3: #6e31ff;
  --colorBrandForeground1: #1477f8;
  --colorBrandForeground2: #81aaff;
  --colorNeutralForegroundInverted1: #f2f0f4;
  --colorNeutralForeground4: #f1f3f8;
  --colorNeutralForeground8: #f9fafc;
  --colorNeutralForeground5: #959ba2;
  --colorNeutralForeground9: #a6a8aa;
  --colorNeutralForeground6: #a9afc6;
  --colorNeutralBackgroundInverted2: #10141f;
  --colorNeutralBackgroundInverted4: #273238;
  --colorNeutralBackgroundInverted3: #2a2b2f;
  --colorNeutralBackground3: #d9dde7;
  --colorPalleteLightBlue: #eeefff;
  --colorNeutralForeground3: #323232;
  --greenBorderColor: #83e1ab;
  --grenBackgroundColor: #dbffea;
  --grenBackgroundColor2: #f5feff;
  --colorPaletteRedForeground1: #fa6b6b;
  --colorPaletteRedForeground2: #bd0202;
  --colorPalettePurpleBackground1: #a19afc;
  --colorPalettePurpleBackground1: #a19afc;
  --colorPaletteGreenBackground1: #1493a4;
  --colorPaletteBlueBackground3: #e4e9ff;
  --colorPaletteGrayBackground1: #f5f6fa;
  --colorPaletteGrayBackground2: #f2f0f4;
  --colorNeutralBackground4: #f5f6fa;
  --graySelectBoxBorder: #cdd3ec;
  --colorShadowInverted1: rgba(61, 66, 77, 0.09);
  --colorShadowInverted2: rgba(151, 152, 153, 0.05);
  --colorGrayBackGradient: linear-gradient(0deg, #cdd3ec, #cdd3ec);
  --colorGrayForeground6: #d8d8d8;
  --colorPaletteGrayBackground3: #efedf1;
}
